import React from "react"
import Seo from "../components/seo"
import Video from "../components/video"
import Footer from "../components/footer"
import "../css/style.css"

const CotorreoPage = () => {
  return (
    <>
      <Seo title="Cotorreo" />
      <Video />
      <div className="footer-1">
        <Footer />
      </div>
    </>
  )
}

export default CotorreoPage
